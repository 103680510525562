import * as i0 from '@angular/core';
import { Injectable, InjectionToken, NgModule } from '@angular/core';
import { Config, facadeFactory, provideDefaultConfigFactory, provideDefaultConfig } from '@spartacus/core';
import * as i1 from '@angular/router';
import { RouterModule } from '@angular/router';
import { ORDER_ENTRIES_CONTEXT } from '@spartacus/cart/base/root';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const defaultQuickOrderConfig = {
  quickOrder: {
    searchForm: {
      displayProductImages: true,
      maxProducts: 5,
      minCharactersBeforeRequest: 3
    },
    list: {
      hardDeleteTimeout: 240000
    }
  }
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class QuickOrderConfig {
  static {
    this.ɵfac = function QuickOrderConfig_Factory(t) {
      return new (t || QuickOrderConfig)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: QuickOrderConfig,
      factory: function QuickOrderConfig_Factory(t) {
        let r = null;
        if (t) {
          r = new (t || QuickOrderConfig)();
        } else {
          r = i0.ɵɵinject(Config);
        }
        return r;
      },
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuickOrderConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useExisting: Config
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const CART_QUICK_ORDER_FEATURE = 'cartQuickOrder';
const CART_QUICK_ORDER_CORE_FEATURE = 'cartQuickOrderCore';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
function quickOrderFacadeFactory() {
  return facadeFactory({
    facade: QuickOrderFacade,
    feature: CART_QUICK_ORDER_CORE_FEATURE,
    methods: ['addProduct', 'addToCart', 'clearList', 'canAdd', 'setListLimit', 'getEntries', 'getProductAdded', 'loadEntries', 'softDeleteEntry', 'searchProducts', 'setProductAdded', 'updateEntryQuantity', 'getSoftDeletedEntries', 'restoreSoftDeletedEntry', 'hardDeleteEntry', 'clearDeletedEntries', 'getNonPurchasableProductError', 'setNonPurchasableProductError', 'clearNonPurchasableProductError']
  });
}
class QuickOrderFacade {
  static {
    this.ɵfac = function QuickOrderFacade_Factory(t) {
      return new (t || QuickOrderFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: QuickOrderFacade,
      factory: () => quickOrderFacadeFactory(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuickOrderFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: quickOrderFacadeFactory
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const QuickOrderOrderEntriesContextToken = new InjectionToken('QuickOrderOrderEntriesContext');

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
function defaultQuickOrderComponentsConfig() {
  const config = {
    featureModules: {
      [CART_QUICK_ORDER_FEATURE]: {
        cmsComponents: ['QuickOrderComponent', 'CartQuickOrderFormComponent']
      },
      // by default core is bundled together with components
      [CART_QUICK_ORDER_CORE_FEATURE]: CART_QUICK_ORDER_FEATURE
    }
  };
  return config;
}
const defaultQuickOrderRoutingConfig = {
  routing: {
    routes: {
      quickOrder: {
        paths: ['my-account/quick-order']
      }
    }
  }
};
class QuickOrderRootModule {
  static {
    this.ɵfac = function QuickOrderRootModule_Factory(t) {
      return new (t || QuickOrderRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: QuickOrderRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfigFactory(defaultQuickOrderComponentsConfig), provideDefaultConfig(defaultQuickOrderRoutingConfig), provideDefaultConfig(defaultQuickOrderConfig)],
      imports: [RouterModule.forChild([{
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'quickOrder',
          cxContext: {
            [ORDER_ENTRIES_CONTEXT]: QuickOrderOrderEntriesContextToken
          }
        }
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuickOrderRootModule, [{
    type: NgModule,
    args: [{
      imports: [RouterModule.forChild([{
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'quickOrder',
          cxContext: {
            [ORDER_ENTRIES_CONTEXT]: QuickOrderOrderEntriesContextToken
          }
        }
      }])],
      providers: [provideDefaultConfigFactory(defaultQuickOrderComponentsConfig), provideDefaultConfig(defaultQuickOrderRoutingConfig), provideDefaultConfig(defaultQuickOrderConfig)]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CART_QUICK_ORDER_CORE_FEATURE, CART_QUICK_ORDER_FEATURE, QuickOrderConfig, QuickOrderFacade, QuickOrderOrderEntriesContextToken, QuickOrderRootModule, defaultQuickOrderComponentsConfig, defaultQuickOrderConfig, defaultQuickOrderRoutingConfig, quickOrderFacadeFactory };
